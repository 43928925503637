import React, { useContext, useState, useRef, useEffect } from "react";
import { FaLink } from "react-icons/fa";
import { ControlButtons } from "./ControlButtons";
import "./styles.css";
import { VideoContext } from "../../../context/videoProvider";
import { SessionContext } from "../../../context/sessionProvider";
import { UserContext } from "../../../context/userProvider";

export function MenuBar() {
  const { nameParticipant, sessionName } = useContext(UserContext);
  const { getParticipantsCount } = useContext(VideoContext);
  const {
    leaveSession,
    estouCompartilhandoTela,
    shareScreen,
    stopShareScreen,
  } = useContext(SessionContext);
  const windowSize = window.innerWidth;

  const copyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/${sessionName}`);
  };

  return (
    <div className="menu-bar-container">
      {windowSize > 768 && (
        <div className="sala-info">
          <div className="sala-info-link">
            <h3>{nameParticipant}</h3>
            <FaLink onClick={copyLink} title="Copiar link da reunião" />
          </div>
          <p>Total de participantes: {getParticipantsCount()}</p>
        </div>
      )}
      <ControlButtons
        windowSize={windowSize}
        stopShareScreen={stopShareScreen}
        shareScreen={shareScreen}
        estouCompartilhandoTela={estouCompartilhandoTela}
      />
      <button className="logout-button" onClick={() => leaveSession()}>
        Sair
      </button>
    </div>
  );
}
