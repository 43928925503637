import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import { SessionContext } from "./sessionProvider";
import { UserContext } from "./userProvider";
import userJoinSound from "../assets/sounds/userJoinSound.mp3";

export const VideoContext = createContext();

export function VideoProvider({ children }) {
  const {
    session,
    mainStreamManager,
    publisher,
    setPublisher,
    subscribers,
    setSubscribers,
    OV,
  } = useContext(SessionContext);
  const { video, setVideo, setAudio, socket, setNotification, userId } =
    useContext(UserContext);

  const [isExpandScreen, setIsExpandScreen] = useState({
    ativo: false,
    id: userId,
  });

  const deleteSubscriber = (streamManager) => {
    setSubscribers((prevSubscribers) =>
      prevSubscribers.filter((subscriber) => subscriber !== streamManager)
    );
  };

  const switchCamera = async () => {
    try {
      const devices = await OV.getDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length > 1) {
        const currentVideoDeviceId = mainStreamManager.stream
          .getMediaStream()
          .getVideoTracks()[0]
          .getSettings().deviceId;
        const newVideoDevice = videoDevices.find(
          (device) => device.deviceId !== currentVideoDeviceId
        );

        if (newVideoDevice) {
          const newPublisher = OV.initPublisher(undefined, {
            videoSource: newVideoDevice.deviceId,
            publishAudio: true,
            publishVideo: true,
            mirror: true,
          });

          await session.unpublish(mainStreamManager);
          await session.publish(newPublisher);

          setPublisher(newPublisher);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleAudioMute = () => {
    if (publisher) {
      const audioActive = publisher.stream.audioActive;
      publisher.publishAudio(!audioActive);
      setAudio(!audioActive);


      const signalData = {
        streamId: publisher.stream.streamId,
        isMuted: !audioActive, 
      };
  
      session.signal({
        data: JSON.stringify(signalData), 
        to: [],
        type: 'newStatus',
      }).then(() => {
        console.log('Sinal enviado com sucesso!');
      }).catch(error => {
        console.error('Erro ao enviar sinal:', error);
      });
    }
  };

  const toggleVideo = () => {
    if (publisher) {
      const videoActive = publisher.stream.videoActive;
      publisher.publishVideo(!videoActive);
      setVideo(!video);
    }
  };

  const getParticipantsCount = () => {
    return session ? subscribers.length + 1 : null;
  };

  const userDisconected = useCallback(({ userId, username }) => {
    if (username && userId)
      setNotification({
        text: `${username} saiu da sala`,
        type: "disconnect",
      });
  }, []);

  useEffect(() => {
    if (socket) {
      const handleUserJoined = ({ userId, username }) => {
        if (userId) {
          setNotification({
            text: `${username} entrou na sala`,
            type: "normal",
          });
          const song = new Audio(userJoinSound);
          song.volume = 0.1;
          song
            .play()
            .catch((error) =>
              console.error("Erro ao reproduzir o som:", error)
            );
        }
      };

      socket.on("user-joined", handleUserJoined);
      return () => {
        socket.off("user-joined", handleUserJoined);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("user-disconnected", userDisconected);
    }
    return () => {
      if (socket) socket.off("user-disconnected");
    };
  }, [socket]);

  return (
    <VideoContext.Provider
      value={{
        deleteSubscriber,
        switchCamera,
        toggleAudioMute,
        toggleVideo,
        getParticipantsCount,
        isExpandScreen,
        setIsExpandScreen,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}
