import React, { useState, useContext, useEffect } from "react";
import {
  BsMic,
  BsMicMute,
  BsCameraVideo,
  BsCameraVideoOff,
  BsChatSquareText,
  BsDisplay,
} from "react-icons/bs";
import { VideoContext } from "../../../../context/videoProvider";
import { ChatContext } from "../../../../context/chatProvider";
import { UserContext } from "../../../../context/userProvider";

export function ControlButtons({
  windowSize,
  estouCompartilhandoTela,
  shareScreen,
  stopShareScreen,
}) {
  const { audio, video } = useContext(UserContext);
  const { toggleAudioMute, toggleVideo } = useContext(VideoContext);
  const { messages, isChatOpen, setIsChatOpen } = useContext(ChatContext);

  const openChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className="control-buttons">
      <div
        className={`button-icon ${audio ? "off" : ""}`}
        onClick={() => toggleAudioMute()}
      >
        {audio ? <BsMic /> : <BsMicMute style={{ color: "#9ac31c" }} />}
        <p>Microfone</p>
      </div>

      <div
        className={`button-icon ${video ? "off" : ""}`}
        onClick={() => toggleVideo()}
      >
        {video ? (
          <BsCameraVideo />
        ) : (
          <BsCameraVideoOff style={{ color: "#9ac31c" }} />
        )}
        <p>Câmera</p>
      </div>

      <div className="button-icon" onClick={openChat}>
        <BsChatSquareText />
        {messages?.length > 0 && !isChatOpen && (
          <span className="new-message-dot"></span>
        )}
        <p>Bate-papo</p>
      </div>

      <div
        className="button-icon"
        style={{ display: windowSize > 768 ? "block" : "none" }}
        onClick={estouCompartilhandoTela ? stopShareScreen : shareScreen}
      >
        {estouCompartilhandoTela ? (
          <div className="icon-container">
            <div className="display-icon-with-strike">
              <BsDisplay />
            </div>
            <p>Interromper</p>
          </div>
        ) : (
          <>
            <BsDisplay />
            <p>Compartilhar</p>
          </>
        )}
      </div>
    </div>
  );
}
