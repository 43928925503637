import React from "react";

const TitleDetails = () => {
  return (
    <div className="content-title">
      <p className="pulse">Em apenas um clique você cria sua sala !!</p>
      <h2>Agora, insira o nome e clique em "Criar".</h2>
    </div>
  );
};

export default TitleDetails;
