import { Route, Routes } from "react-router";
import Login from "../components/Login";
import Transmissao from "../components/Transmissao";
import Redirecionar from "../components/Redirecionar";
import NovaSala from "../components/NovaSala";
import { VideoProvider } from "../context/videoProvider";
import { ChatProvider } from "../context/chatProvider";
import { SessionProvider } from "../context/sessionProvider";
import CustomRoom from "./../components/CustomRoom";

export default function Routeror() {
  return (
    <Routes>
      <Route path="/" element={<Redirecionar />} />
      <Route path="/builder/:id" element={<NovaSala />} />
      <Route path="/:linkNome" element={<Login />} />
      <Route
        path="/customRoom/:prefId/:salaId/:linkSala"
        element={<CustomRoom />}
      />
      <Route
        path="/transmissao"
        element={
          <SessionProvider>
            <ChatProvider>
              <VideoProvider>
                <Transmissao />
              </VideoProvider>
            </ChatProvider>
          </SessionProvider>
        }
      />
    </Routes>
  );
}
