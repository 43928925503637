import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { OpenVidu } from "openvidu-browser";
import { UserContext } from "./userProvider";
import { useNavigate } from "react-router";

import ApiUsers from "../ApiUsers";

export const SessionContext = createContext();

export function SessionProvider({ children }) {
  const {
    audio,
    video,
    nameParticipant,
    setNameParticipant,
    sessionName,
    setSessionName,
    socket,
    salaId,
    dbname,
    userId,
    isAdmin,
    setNotification,
    configDevices,
    debbugador
  } = useContext(UserContext);
  const [session, setSession] = useState(undefined);
  const [publisher, setPublisher] = useState(undefined);
  const [subscribers, setSubscribers] = useState([]);
  const [estouCompartilhandoTela, setEstouCompartilhandoTela] = useState(false);
  const [OV, setOV] = useState(new OpenVidu());
  const videoRef = useRef(null);
  const [sessionScreen, setSessionScreen] = useState(null);

  const navigate = useNavigate();

  const navigateToEnd = () => {
    navigate("/");
  };

  const deleteSubscriber = (streamManager) => {
    setSubscribers((prevSubscribers) =>
      prevSubscribers.filter((subscriber) => subscriber !== streamManager)
    );
  };

  const joinSession = async () => {
    console.log("JOIN");
    const newOV = new OpenVidu();
    const newSession = newOV.initSession();

    newSession.on("streamCreated", (e) => {
      const subscriber = newSession.subscribe(e.stream, undefined);
      console.log("SUBSCRIBER", subscriber);
      debbugador(subscriber);
      setSubscribers((prevSubscribers) => [...prevSubscribers, subscriber]);      
    });

    newSession.on("streamDestroyed", (e) => {
      deleteSubscriber(e.stream.streamManager);
    });

    newSession.on("exception", (exception) => {
      console.error(exception);
    });

    socket.emit("join-room", {
      salaId,
      username: nameParticipant,
      userId,
      dbname,
      remoteAudio: true,
      remoteVideo: true,
    });

    const checkPermissions = async () => {
      try {
        const videoPermission = await navigator.permissions.query({
          name: "camera",
        });
        const audioPermission = await navigator.permissions.query({
          name: "microphone",
        });

        if (
          videoPermission.state !== "granted" ||
          audioPermission.state !== "granted"
        ) {
          console.error("Permissões de câmera ou microfone não concedidas");
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    };

    const permissionsGranted = await checkPermissions();
    // if (!permissionsGranted) {
    //   navigateToEnd();
    //   return;
    // }

    const token = await getToken();
    try {
      await newSession.connect(token, { clientData: nameParticipant });

      const newPublisher = await newOV.initPublisherAsync(undefined, {
        audioSource: configDevices.selectedAudioDevice,
        videoSource: configDevices.selectedVideoDevice,
        publishAudio: audio,
        publishVideo: video,
        resolution: "640x480",
        frameRate: 30,
        insertMode: "APPEND",
        mirror: false,
      });
      console.log("PUBLISHER", newPublisher);
      debbugador({
        audioSource: configDevices.selectedAudioDevice,
        videoSource: configDevices.selectedVideoDevice,
        publishAudio: audio,
        publishVideo: video,
      });
      // newPublisher.once("accessAllowed", () => {
      //   newSession.publish(newPublisher).catch((error) => {
      //     console.error("Erro ao publicar o publisher:", error);
      //   });
      // });
      await newSession.publish(newPublisher).catch((error) => {
        console.error("Erro ao publicar o publisher:", error);
      });
      setPublisher(newPublisher);
      setOV(newOV);
      setSession(newSession);
    } catch (error) {
      console.error("Houve um erro ao se conectar na sessão:", error);
      debbugador(error);
    }
  };

  const leaveSession = () => {
    if (session) {
      session.disconnect();
      socket.emit("leave-room", {
        salaId,
        userId,
        username: nameParticipant,
        dbname,
      });
      navigateToEnd();
      setSession(undefined);
      setSubscribers([]);
      setSessionName("");
      setNameParticipant("");
      setPublisher(undefined);
      setOV(new OpenVidu());
    }
  };

  const shareScreen = async () => {
    try {
      const newOV = new OpenVidu();
      const newSessionScreen = newOV.initSession();
      setSessionScreen(newSessionScreen);

      const token = await getToken();
      await newSessionScreen.connect(token);

      const screenPublisher = newOV.initPublisher(undefined, {
        videoSource: "screen",
        audioSource: "screen",
      });

      screenPublisher.once("accessAllowed", () => {
        newSessionScreen
          .publish(screenPublisher)
          .then(() => {
            const videoTracks = screenPublisher.stream
              .getMediaStream()
              .getVideoTracks();

            videoTracks.forEach((track) => {
              track.addEventListener("ended", () => {
                newSessionScreen.disconnect();
                setEstouCompartilhandoTela(false);
                setSessionScreen(null);
              });
            });

            setEstouCompartilhandoTela(true);

            if (videoRef.current) {
              videoRef.current.srcObject =
                screenPublisher.stream.getMediaStream();
            }
          })
          .catch((error) => {
            console.error(
              "Erro ao publicar o compartilhamento de tela:",
              error
            );
          });
      });

      screenPublisher.once("accessDenied", () => {
        console.log("Compartilhamento de tela: Acesso negado pelo usuário");
      });
    } catch (error) {
      console.error(
        "Houve um erro ao conectar à sessão:",
        error.code,
        error.message
      );
    }
  };

  const stopShareScreen = async () => {
    if (sessionScreen) {
      sessionScreen.disconnect();
      setEstouCompartilhandoTela(false);
      setSessionScreen(null);
    }
  };

  const getToken = async () => {
    const sessionId = await createSession(sessionName);
    return await createToken(sessionId);
  };

  const createSession = async (sessionId) => {
    try {
      const response = await ApiUsers.post("/api/sessions", {
        customSessionId: sessionId,
      });
      return response.data;
    } catch (error) {
      console.error("Erro ao criar a sessão", error);
    }
  };

  const createToken = async (sessionId) => {
    const response = await ApiUsers.post(
      "/api/sessions/" + sessionId + "/connections",
      {},
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  };

  useEffect(() => {
    console.log("ADMIN 1", isAdmin);
    if (socket && isAdmin) {
      console.log("ADMIN 2", isAdmin);
      socket.on("user-waiting-join", (user) => {
        console.log("user-waiting-join", user);
        const userWaitingJoin = {
          username: user.username,
          userId: user.userId,
        };
        setNotification({
          type: "acceptWaitRoom",
          waitingRoom: [userWaitingJoin],
        });
      });

      socket.on("waiting-room", (userWaitingJoin) => {
        setNotification({
          type: "acceptWaitRoom",
          waitingRoom: userWaitingJoin,
        });
      });
    }
    return () => {
      if (socket) {
        socket.off("user-waiting-join");
        socket.off("waiting-room");
      }
    };
  }, [socket, isAdmin]);

  useEffect(() => {
    console.log("sessionName", sessionName);
    if (sessionName.length !== 0 && socket) {
      joinSession();
    }
  }, [sessionName, socket]);

  useEffect(() => {
    const beforeUnloadHandler = (event) => {
      event.preventDefault();
      event.returnValue = "";
      leaveSession();
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        publisher,
        subscribers,
        OV,
        joinSession,
        leaveSession,
        shareScreen,
        stopShareScreen,
        estouCompartilhandoTela,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
