import { createContext, useState, useContext, useEffect } from "react";
import { SessionContext } from "./sessionProvider";
import { UserContext } from "./userProvider";

export const ChatContext = createContext();

export function ChatProvider({ children }) {
  const { nameParticipant } = useContext(UserContext);
  const { session } = useContext(SessionContext);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);

  const addChatMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const handleSendMessage = () => {
    if (session) {
      const messageData = { sender: nameParticipant, message: newMessage };
      const data = JSON.stringify(messageData);

      session.signal({ type: "chat", data }, (error) => {
        if (error) {
          console.error("Erro ao enviar mensagem:", error.message);
        }
      });
      setNewMessage("");
    }
  };

  const onSendMessage = () => {
    if (newMessage) {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (!session) return;
    session.on("signal:chat", (event) => {
      const message = JSON.parse(event.data);
      addChatMessage(message);
    });
  }, [session]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        newMessage,
        setNewMessage,
        isChatOpen,
        setIsChatOpen,
        addChatMessage,
        handleSendMessage,
        onSendMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
