import React, { useEffect, useRef, useContext, useState } from "react";
import VideoHeader from "./VideoHeader";
import "./styles.css";
import { SessionContext } from "../../../../context/sessionProvider";
import { BiSolidMicrophone, BiSolidMicrophoneOff  } from "react-icons/bi";

function Video({ streamManager, name}) {
  const videoRef = useRef();
  const { status } = useContext(SessionContext);
  // const [statusClone, setStatusClone] = useState();
  // const icon = { 
  //   false: <BiSolidMicrophoneOff size={30} color={"#111"} />,
  //   true: <BiSolidMicrophone size={30} color={"#111"} />
  // }

  // useEffect(() => {
  //   let newStatus = status ? [...status] : [];
  //   setStatusClone(newStatus);
  // }
  // , [status]);

  useEffect(() => {
    if (streamManager && videoRef.current) {
      streamManager.addVideoElement(videoRef.current);
    }
  }, [streamManager]);

  // useEffect(() => {
  //   status.forEach((item) => {
  //     if(item.streamId === streamManager.stream.streamId && item.isMuted === true) {
  //       console.log(streamManager)
  //       setShowIcon(true);
  //     } else {
  //       setShowIcon(false);
  //     }
  //   });
  // }, [status]);


  return (
    <div className={"video-player-container"}>
      <video className={"video-player"} autoPlay={true} ref={videoRef} style={
        name === 'Tela compartilhada' ? { objectFit: "contain" } : { objectFit: "cover" }
      } />
      {/* {icon[statusClone?.find(item => item.streamId === streamManager.stream.streamId)?.isMuted]} */}
      <VideoHeader name={name} stream={streamManager} videoRef={videoRef} />
    </div>
  );
}

export default Video;
