import { useState, useRef, useContext, useCallback, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";
import "./styles.css";
import { ChatContext } from "../../context/chatProvider";

function Chat() {
  const {
    messages,
    newMessage,
    setNewMessage,
    onSendMessage,
    isChatOpen,
    setIsChatOpen,
  } = useContext(ChatContext);
  const emojiRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);

  const handleClickOutside = useCallback(
    (event) => {
      if (event.target.className.includes("emoji")) {
        setShowEmojis(true);
      } else if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    },
    [setShowEmojis],
  );

  useEffect(() => {
    if (showEmojis) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showEmojis, handleClickOutside]);

  const handleChangeMessage = (e) => {
    setNewMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSendMessage();
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const closeChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      <div className={`chat-container-${isChatOpen ? "open" : "closed"}`}>
        <div className="chat-header">
          <FaArrowRight
            style={{ color: "#142a4c", cursor: "pointer" }}
            onClick={closeChat}
          />
          <h2>Bate-papo</h2>
        </div>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className="chat-message">
              <span className="sender">{message.sender}: </span>
              <span className="message">{message.message}</span>
            </div>
          ))}
          <div className="emoji-picker" ref={emojiRef}>
            {showEmojis && <EmojiPicker onEmojiClick={handleEmojiClick} />}
          </div>
        </div>

        <div className="chat-input">
          <input
            type="text"
            placeholder="Digite sua mensagem..."
            value={newMessage}
            onChange={handleChangeMessage}
            onKeyUp={handleKeyPress}
          />
          <button
            ref={emojiRef}
            className="emoji-button"
            onClick={() => setShowEmojis(!showEmojis)}
          >
            😀
          </button>
          <button className="send-message-button" onClick={onSendMessage}>
            Enviar
          </button>
        </div>
      </div>
    </>
  );
}

export default Chat;
