import React, { useState, useEffect, useContext } from "react";
import VideoRoom from "./VideoRoom";
import "./styles.css";
import Logo from "../../assets/images/Marca com Slogan do lado-01.png";
import LogoMobile from "../../assets/images/VOX LOGO Vertical.png";
import { MenuBar } from "./MenuBar";
import Chat from "../Chat/index";
import { UserContext } from "../../context/userProvider";
import { VideoContext } from "../../context/videoProvider";
import { ChatContext } from "../../context/chatProvider";

function Transmissao() {
  const { nameParticipant } = useContext(UserContext);
  const { getParticipantsCount } = useContext(VideoContext);
  const { isChatOpen } = useContext(ChatContext);

  const [videoContainerWidth, setVideoContainerWidth] = useState("100%");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const numUsers = getParticipantsCount();
    const containerWidth = `${100 / (numUsers + 1)}%`;
    setVideoContainerWidth(containerWidth);
  }, [getParticipantsCount]);

  const calcWindowSize = () => {
    const numUsers = getParticipantsCount();
    const windowSize = window.innerWidth;
    let calculo = windowSize / (numUsers + 1);

    if (calculo <= 200) {
      return 200;
    } else if (calculo >= 400 && !isChatOpen) {
      return 400;
    } else if (isChatOpen) {
      const percent = windowSize * 0.3;
      calculo = (windowSize - percent) / (numUsers + 1);
      if (calculo <= 200) {
        return 250;
      }
      return calculo;
    } else {
      return calculo;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="transmissao-container">
        <div className="header">
          <img
            src={windowSize.width < 768 ? LogoMobile : Logo}
            alt="Vox City"
          />
          {windowSize.width < 768 && <h2>{nameParticipant}</h2>}
        </div>
        <div className={`box-video-container${isChatOpen ? "-chat-open" : ""}`}>
          <VideoRoom calcWindowSize={calcWindowSize} />
        </div>
        <MenuBar />
      </div>
      <Chat />
    </>
  );
}

export default Transmissao;
