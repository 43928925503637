import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ClipLoader } from "react-spinners";
import { UserContext } from "../../context/userProvider";

export default function CustomRoom() {
  const navigate = useNavigate();
  const { setSalaId, setPrefId } = useContext(UserContext);
  const { prefId, salaId, linkSala } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setPrefId(prefId);
    setSalaId(salaId);
    setIsLoading(false);
    navigate(`/${linkSala}`);
  }, []);

  return (
    <div className="custom-room">
      <ClipLoader color="#000" loading={isLoading} size={150} />
    </div>
  );
}
