import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../../context/userProvider";
import userAcceptSound from "../../../assets/sounds/userAcceptSound.mp3";
import "react-toastify/dist/ReactToastify.css";

function AcceptWaitRoom(props) {
  const { socket, salaId, setNotifications } = useContext(UserContext);
  const [showUsersWaiting, setShowUsersWaiting] = useState([]);

  useEffect(() => {
    setShowUsersWaiting(props.notification.waitingRoom);
  }, [props.notification.waitingRoom]);

  useEffect(() => {
    showUsersWaiting.forEach((user) => {
      toast(
        <Permission
          onPermit={() => {
            socket.emit("user-allowed", { userId: user.userId, salaId });
            setNotifications((prev) => {
              return [...prev, user.userId];
            });
          }}
          userName={user.username}
          userId={user.userId}
          offPermit={() => {
            socket.emit("user-not-allowed", {
              userId: user.userId,
              salaId,
            });
          }}
        />,
        {
          toastId: user.userId,
          position: "bottom-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          onOpen: () => {
            const audio = new Audio(userAcceptSound);
            audio.volume = 0.1;
            audio.play();
          },
        },
      );
    });
  }, [showUsersWaiting]);

  const Permission = ({ onPermit, closeToast, userName, offPermit }) => {
    const handleClick = () => {
      onPermit();
      closeToast();
    };

    const handleDecline = () => {
      offPermit();
      closeToast();
    };

    return (
      <>
        <p>
          {userName} deseja entrar na sala. Autorizar?
          <br />
          <button className="accept-button" onClick={handleClick}>
            SIM
          </button>
          <button className="decline-button" onClick={handleDecline}>
            NÃO
          </button>
        </p>
      </>
    );
  };

  return null;
}

export default AcceptWaitRoom;
