import React from "react";
import Video from "./Video";
import "./styles.css";

function Stream({ streamManager }) {
  const isExpandScreen = {
    ativo: false,
    disable: false,
  };

  const getNicknameTag = () => {
    let name;
    try {
      name = JSON.parse(streamManager.stream.connection.data).clientData;
    } catch (error) {
      name = "Tela compartilhada";
    }
    return name;
  };

  return (
    <div
      className={`painel-video-container${
        isExpandScreen.ativo === false ? "" : "-expand-screen"
      }`}
    >
      <div
        className={`videos-wrapper-expand-screen-left${
          isExpandScreen.ativo === false ? "-disable" : ""
        }`}
      ></div>
      {streamManager !== undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Video streamManager={streamManager} name={getNicknameTag()}  />
        </div>
      ) : null}
    </div>
  );
}

export default Stream;
