import React from "react";
import { ClipLoader } from "react-spinners";

const Input = ({ nomeSala, handleChange, isLoading, CriarSala }) => {
  return (
    <div className="container-nova-sala">
      <div className="container-nova-sala-input">
        <input
          type="text"
          placeholder="Nome da Sala"
          value={nomeSala}
          onChange={handleChange}
          disabled={isLoading}
        />
        <button onClick={CriarSala} disabled={isLoading}>
          {isLoading ? (
            <ClipLoader color={"#ffffff"} loading={isLoading} size={15} />
          ) : (
            "Criar"
          )}
        </button>
      </div>
    </div>
  );
};

export default Input;
