import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/userProvider";
import Form from "./Form";
import PreVideo from "./Pre";
import "./styles.css";

function Login() {
  const {
    audio,
    setAudio,
    video,
    setVideo,
    setAlert,
    mediaPermission,
    setSessionName,
    login,
    configDevices,
    setConfigDevices,
  } = useContext(UserContext);

  useEffect(() => {
    if (mediaPermission) {
      getAvailableDevices();
    }
  }, [mediaPermission]);

  const getAvailableDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    const audioDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    setConfigDevices({
      ...configDevices,
      videoDevices: videoDevices,
      audioDevices: audioDevices,
      selectedVideoDevice:
        videoDevices.length > 0 ? videoDevices[0].deviceId : "",
      selectedAudioDevice:
        audioDevices.length > 0 ? audioDevices[0].deviceId : "",
    });
  };

  const handleVideoDeviceChange = (e) => {
    setConfigDevices({ ...configDevices, selectedVideoDevice: e.target.value });
  };

  const handleAudioDeviceChange = (e) => {
    setConfigDevices({ ...configDevices, selectedAudioDevice: e.target.value });
  };

  const signIn = () => {
    if (mediaPermission) {
      const currentURL = window.location.pathname;
      const parts = currentURL.split("/");
      if (parts.length === 2) {
        const linkNome = parts[1];
        setSessionName(linkNome);
        login(linkNome);
      } else {
        console.error("URL não contém os parâmetros esperados.");
        setAlert({
          visible: true,
          title: "Negative",
          placeholder:
            "URL não contém os parâmetros esperados entre em contato nos canais da Vox.",
          type: "negative",
        });
      }
    } else {
      setAlert({
        visible: true,
        title: "Negative",
        placeholder:
          "Você precisa permitir o acesso à câmera e ao microfone para fazer login. Após permitir, recarregue a página.",
        type: "negative",
      });
    }
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-left">
          <div className="login-form">
            <Form />
            <button className="login-button" onClick={signIn}>
              {" "}
              Entrar{" "}
            </button>
          </div>
        </div>
        <div className="login-right">
          <PreVideo
            isAudioMuted={audio}
            setIsAudioMuted={setAudio}
            setIsVideoOff={setVideo}
            isVideoOff={video}
          />
          <div className="device-selection">
            <div className="device-selection-item">
              <label htmlFor="videoDevices">Dispositivo de Vídeo:</label>
              <select
                id="videoDevices"
                value={configDevices.selectedVideoDevice}
                onChange={handleVideoDeviceChange}
              >
                {configDevices.videoDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="device-selection-item">
              <label htmlFor="audioDevices">
                Dispositivo de Entrada de Áudio:
              </label>
              <select
                id="audioDevices"
                value={configDevices.selectedAudioDevice}
                onChange={handleAudioDeviceChange}
              >
                {configDevices.audioDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
