import React from "react";
import { UserProvider } from "./context/userProvider";
import Routeror from "./router/Routeror";
import Alert from "./components/Alert";
import Notify from "./components/Notificacao";
import "./App.css";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <Notify />
        <Alert />
        <Routeror />
      </UserProvider>
    </div>
  );
}

export default App;
