import { useContext } from "react";
import { BiUser } from "react-icons/bi";
import { UserContext } from "../../../context/userProvider";

function Form() {
  const { setNameParticipant } = useContext(UserContext);
  const handleUserNameChange = (event) => {
    const userNameLocal = event.target.value;
    setNameParticipant(userNameLocal);
  };
  return (
    <div className="login-input-content">
      <input
        className="login-input"
        type="text"
        placeholder="Nome"
        onChange={(e) => handleUserNameChange(e)}
      />
      <BiUser className="login-icon" />
    </div>
  );
}

export default Form;
