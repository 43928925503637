import { useContext } from "react";
import Normal from "./types/normal";
import Disconnect from "./types/disconnect";
import AcceptWaitRoom from "./types/acceptWaitRoom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../context/userProvider";
import "./styles.css";

function Notify() {
  const { notification } = useContext(UserContext);
  const types = {
    normal: <Normal notification={notification} />,
    disconnect: <Disconnect notification={notification} />,
    acceptWaitRoom: <AcceptWaitRoom notification={notification} />,
  };

  return (
    <div>
      {types[notification.type]}
      <ToastContainer />
    </div>
  );
}

export default Notify;
