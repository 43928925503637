import axios from "axios";

let url = process.env.REACT_APP_APPLICATION_SERVER_URL;

const ApiUsers = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": url,
  },
});

axios.defaults.withCredentials = true;

export default ApiUsers;
