import React, { useState, useContext } from "react";
import "./styles.css";
import { VideoContext } from "../../../../../context/videoProvider";

function VideoHeader({ name, videoRef, stream }) {
  const [showOptions, setShowOptions] = useState(false);
  const { isExpandScreen, setIsExpandScreen } = useContext(VideoContext);


  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  const handleFullscreenClick = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    }
    setShowOptions(false); // Fechar as opções após entrar em tela cheia
  };

  // const handleExpandClick = () => {
  //   console.log("Stream ID:", stream.stream.streamId)
  //   // setIsExpandScreen(!isExpandScreen);
  //   setShowOptions(false); // Fechar as opções após clicar em expandir/comprimir
  // };

  return (
    <div className="video-header">
      <span className="video-name">{name}</span>
      <button className="expand-button" onClick={handleOptionsClick}>
        ⋮
      </button>
      {showOptions && (
        <ul className="options-list">
          <li onClick={handleFullscreenClick}>Tela Cheia</li>
          {/* <li onClick={handleExpandClick}>
            {isExpandScreen.ativo ? "Comprimir" : "Expandir Tela"}
          </li> */}
        </ul>
      )}
    </div>
  );
}

export default VideoHeader;

