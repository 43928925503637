import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userLeftSound from "../../../assets/sounds/userLeftSound.mp3";

function Disconnect(props) {
  const { userId } = props.notification;
  const [showUsersWaiting, setShowUsersWaiting] = useState([]);

  useEffect(() => {
    if (!showUsersWaiting.includes(userId)) {
      setShowUsersWaiting([...showUsersWaiting, userId]);
    }
  }, [props.notification]);

  useEffect(() => {
    let verificar = showUsersWaiting?.some(
      (id) => id === props.notification.userId,
    );
    if (verificar) {
      toast(props.notification.text, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        newestOnTop: true,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
        onOpen: () => {
          const audio = new Audio(userLeftSound);
          audio.volume = 0.1;
          audio.play();
        },
      });
    }
  }, [showUsersWaiting]);

  return null;
}

export default Disconnect;
