import React, { createContext, useState, useEffect } from "react";
import ApiUsers from "../ApiUsers";
import { useNavigate } from "react-router";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { stringify } from 'flatted';

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [alert, setAlert] = useState({
    visible: false,
    title: "",
    placeholder: "",
  });
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [preStream, setPreStream] = useState(null);
  const [video, setVideo] = useState(true);
  const [audio, setAudio] = useState(true);
  const [mediaPermission, setMediaPermission] = useState(false);
  const [nameParticipant, setNameParticipant] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [userId, setUserId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [token, setToken] = useState(null);
  const [salaId, setSalaId] = useState(null);
  const [prefId, setPrefId] = useState(null);
  const [dbname, setDbname] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [configDevices, setConfigDevices] = useState({
    videoDevices: [],
    audioDevices: [],
    selectedVideoDevice: "",
    selectedAudioDevice: "",
  });

  const navigate = useNavigate();

  const navigateToTransmissao = () => {
    navigate("transmissao");
  };



  const debbugador = async (obj) => {
    // console.log("debug enviado", obj);
    // try {
    //   await ApiUsers.post("/debug", stringify(obj));
    // } catch (err) {
    //   console.log(err);
    // }
  }



  const constraintsAudio = {
    audio: true,
    video: false,
  };

  const constraintsVideo = {
    audio: false,
    video: true,
  };

  const login = async (linkNome) => {
    try {
      const response = await ApiUsers.post("/login/login", {
        linkNome,
        nameParticipant,
        userId,
        prefId,
      });

      if (response.data.error) {
        return setAlert({
          visible: true,
          title: "Negative",
          placeholder: response.data.error,
          type: "negative",
        });
      }
      setToken(response.data.token);
      setDbname(response.data.dbname);
      setSalaId(response.data.roomID);

      if (response.data.isAdmin) {
        setPrefId(response.data.prefId);
        setIsAdmin(response.data.isAdmin);
        navigateToTransmissao();
      } else {
        setAlert({
          visible: true,
          title: "Aguarde",
          placeholder: "Aguarde autorização para entrar na sala.",
          type: "wait",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const generateUniqueId = () => {
    return uuidv4();
  };

  useEffect(() => {
    if (token && !socket) {
      let url = process.env.REACT_APP_SOCKET_URL;
      const newSocket = io.connect(url, {
        query: {
          token: token,
        },
      });
      setSocket(newSocket);
    }
    return () => { };
  }, [socket, token]);

  async function requestMediaPermission() {
    try {
      const videoStream =
        await navigator.mediaDevices.getUserMedia(constraintsVideo);
      const audioStream =
        await navigator.mediaDevices.getUserMedia(constraintsAudio);

      if (videoStream && audioStream) {
        const combinedStream = new MediaStream([
          ...videoStream.getTracks(),
          ...audioStream.getTracks(),
        ]);
        setPreStream(combinedStream);
        setMediaPermission(true);
      } else {
        setMediaPermission(false);
      }
      return;
    } catch (error) {
      console.error("Erro ao obter as permissões:", error);
      setMediaPermission(false);
      return;
    }
  }

  useEffect(() => {
    requestMediaPermission();
  }, []);

  useEffect(() => {
    if (socket)
      socket.on("user-not-allowed-peer", (id) => {
        if (userId === id) {
          setAlert({
            visible: true,
            title: "Negative",
            placeholder:
              "Sua entrada na sala não foi autorizada. Entre em contato com o administrador da sala.",
            type: "negative",
          });
        }
      });

    return () => {
      if (socket) socket.off("user-not-allowed-peer");
    };
  }, [socket]);

  useEffect(() => {
    const getCookie = (name) => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    const userIdCookie = getCookie("userId");
    const prefIdCookie = getCookie("prefId");
    if (userIdCookie) {
      setUserId(userIdCookie);
      // if(prefIdCookie) {
      //   setPrefId(prefIdCookie);
      // }
    } else if (prefIdCookie) {
      setPrefId(prefIdCookie);
      const newUserId = generateUniqueId();
      setUserId(newUserId);
    } else {
      const newUserId = generateUniqueId();
      setUserId(newUserId);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        alert,
        setAlert,
        socket,
        notification,
        setNotification,
        notifications,
        setNotifications,
        preStream,
        setPreStream,
        video,
        setVideo,
        audio,
        setAudio,
        mediaPermission,
        nameParticipant,
        setNameParticipant,
        sessionName,
        setSessionName,
        userId,
        dbname,
        login,
        isAdmin,
        salaId,
        setSalaId,
        setPrefId,
        prefId,
        configDevices,
        setConfigDevices,
        isLoading,
        setIsLoading,
        showModal,
        setShowModal,
        debbugador,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
