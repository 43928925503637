import { useContext } from "react";
import Stream from "../Stream";
import { SessionContext } from "../../../context/sessionProvider";
import { VideoContext } from "../../../context/videoProvider";

function VideoRoom({ calcWindowSize }) {
  const { publisher, session, subscribers } = useContext(SessionContext);
  const { isExpandScreen } = useContext(VideoContext);

  return (
    <div className="painel-video-container">
      {session !== undefined ? (
        <div
          className={'videos-wrapper'}
          style={{
            display: "grid",
            gap: "15px",
            gridTemplateColumns: `repeat(auto-fit, minmax(${calcWindowSize()}px, 1fr))`,
            gridAutoRows: `${calcWindowSize()}px`,
          }}
        >
          {publisher !== undefined ? (
            <div key={publisher.stream.streamId}>
              <Stream streamManager={publisher} />
            </div>
          ) : null}
          {subscribers.map((sub) => (
            <div key={sub.stream.streamId}>
              <Stream streamManager={sub} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default VideoRoom;
