import { useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../context/userProvider";
import {
  BsMic,
  BsMicMute,
  BsCameraVideo,
  BsCameraVideoOff,
} from "react-icons/bs";
import "./styles.css";

function PreVideo({
  setIsAudioMuted,
  isAudioMuted,
  setIsVideoOff,
  isVideoOff,
}) {
  const { preStream } = useContext(UserContext);
  const videoRef = useRef(null);
  const videoTrack = preStream?.getVideoTracks()[0]?.enabled;
  const audioTrack = preStream?.getAudioTracks()[0]?.enabled;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = preStream;
    }
  }, [preStream]);

  const handleAudioChange = () => {
    if (preStream?.getAudioTracks()) {
      preStream.getAudioTracks()[0].enabled =
        !preStream?.getAudioTracks()[0]?.enabled;
    }
    setIsAudioMuted(!isAudioMuted);
  };

  const handleVideoChange = () => {
    if (preStream?.getVideoTracks()) {
      preStream.getVideoTracks()[0].enabled =
        !preStream?.getVideoTracks()[0]?.enabled;
    }
    setIsVideoOff(!isVideoOff);
  };

  return (
    <div className="pre-video-container">
      <video
        className={`pre-video-player ${videoTrack === undefined ? "off" : ""}`}
        ref={videoRef}
        autoPlay
        muted
        playsInline
      />

      <div className="pre-video-footer-button-container">
        {audioTrack === false ? (
          <BsMicMute
            className={`pre-button-icon ${audioTrack === undefined ? "off" : ""}`}
            style={{ color: "#9ac31c" }}
            onClick={handleAudioChange}
          />
        ) : (
          <BsMic className="pre-button-icon" onClick={handleAudioChange} />
        )}
        {videoTrack === false || videoTrack === undefined ? (
          <BsCameraVideoOff
            className={`pre-button-icon ${videoTrack === undefined ? "off" : ""}`}
            style={{ color: "#9ac31c" }}
            onClick={videoTrack === undefined ? () => null : handleVideoChange}
          />
        ) : (
          <BsCameraVideo
            className="pre-button-icon"
            onClick={handleVideoChange}
          />
        )}
      </div>
    </div>
  );
}

export default PreVideo;
